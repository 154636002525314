






























import { mapActions, mapGetters } from 'vuex'
import { INotification } from '@/interfaces'
import {
  getNotificationDescription,
  redirectToNotificationRoute,
} from '@/utils/NotificationUtil/NotificationUtil'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default {
  name: 'Notifications',
  components: { LoadMoreButton },
  inject: ['enums'],
  data() {
    return {
      redirectToNotificationRoute: redirectToNotificationRoute,
      tableHeaders: [
        {
          text: this.$t('common.form.created.label'),
          value: 'label',
          width: 180,
          sortable: false,
        },
        {
          text: this.$t('common.label.notification'),
          value: 'notification',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    ...mapGetters('notification', [
      'allNotifications',
      'isLoadingNext',
      'next',
      'getIsLoading',
    ]),
  },
  mounted() {
    this.fetchAllNotifications({ organisationId: this.user.organisation.id })
  },
  methods: {
    ...mapActions('notification', ['fetchAllNotifications']),
    getDescriptionMessage(notification: INotification) {
      const description = getNotificationDescription(notification, this.user)

      return description
        ? description.message
        : `${notification.resource} ${notification.action}`
    },
  },
}
